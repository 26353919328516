import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Hero extends React.Component {
    constructor(props) {
        super(props);
        this.title = this.props.title
        this.description = this.props.description
    }
    
    render() {
        return (
            <section id='banner' className='homepage'>
                <Container>
                    <Row>
                        <Col xs='12' sm='12' md='12' lg='12'>
                            <Col className='jumbotron m-auto' xs='8' sm='8' md='8' lg='8'>
                                <h1>{this.title}</h1>
                                <h3>{this.description}</h3>
                            </Col>
                            <button className='btn btn-primary center-block'><a href='/about/'>Learn More</a></button>
                            <button className='btn btn-primary center-block'><a href='/team/'>Meet the Team</a></button>
                        </Col>
                    </Row>
                </Container>
                <div className='arrow bounce'>
                    <Link to='scrollDown' activeClass='active' smooth='true'><FontAwesomeIcon className='arrowBtn' icon='arrow-circle-down' /></Link>
                </div>
            </section>
        )
    }
}

export default Hero
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Col, Row } from "react-bootstrap"
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'

import IndexSeoImage from '../assets/images/urban-development.jpg'

import LogoAion from '../assets/images/clients/logo-AION.png'
import LogoAzura from '../assets/images/clients/logo-azura.png'
import LogoBuckeye from '../assets/images/clients/logo-buckeye.png'
import LogoBurgerKing from '../assets/images/clients/logo-burger-king.png'
import LogoCarrols from '../assets/images/clients/logo-carrols.png'
import LogoCarvana from '../assets/images/clients/logo-carvana.png'
import LogoDavita from '../assets/images/clients/logo-davita.png'
import LogoFedex from '../assets/images/clients/logo-FedExFreight.png'
import LogoFiamma from '../assets/images/clients/logo-fiamma.png'
import LogoGrt from '../assets/images/clients/logo-GRT.png'
import LogoMorrisAnimalInn from '../assets/images/clients/logo-morris-animal-inn.png'
import LogoMpac from '../assets/images/clients/logo-MPAC.png'
import LogoOewcsl from '../assets/images/clients/logo-OEWCSL.png'
import LogoPanda from '../assets/images/clients/logo-panda.png'
import LogoPopeyes from '../assets/images/clients/logo-popeyes.png'
import LogoQts from '../assets/images/clients/logo-QTS.png'
import LogoSilverman from '../assets/images/clients/logo-silverman.png'
import LogoSmg from '../assets/images/clients/logo-SMG.png'
import LogoTd from '../assets/images/clients/logo-TD.png'
import LogoWoodmont from '../assets/images/clients/logo-woodmont.png'

class Index extends React.Component {
    componentDidMount() {
        const initCurator = () => {
            (function(){
            var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
            i.src = "https://cdn.curator.io/published/e2396545-0f58-4a28-a3f2-71481c7c082e.js";
            e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
            })()
        }
        initCurator()
    }

    render() {
        return (
            <StaticQuery
            query={graphql`
                query HomeMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                            social {
                                facebook {
                                    url
                                }
                                instagramUrl
                                linkedInUrl
                            }
                            team {
                                simone {
                                    name
                                    emailUrl
                                }
                                larry {
                                    name
                                    emailUrl
                                }
                            }
                        }
                    }        
                }
            `}
            render={data => (
                <>
                <Layout page='index'>
                    <Seo title={`Welcome to ${data.site.siteMetadata.title}`} description={data.site.siteMetadata.description} image={IndexSeoImage} keywords={[``]} />
                    <Hero title={`${data.site.siteMetadata.title} specializes in all aspects of real estate development.`} description={`With over two decades of combined legal experience in this field of law, we swiftly and efficiently navigate through the municipal, county and state agency land development approval process faced by developers.`} />
                    <section id='team' className='scrollDown'>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12'>
                                    <Col xs='12' sm='12' md='12' lg='12'>
                                        <h1 className='text-center'>Meet the Team</h1>
                                        <p className='text-center'>With over two decades of combined legal experience in this field of law, we swiftly and efficiently navigate through the municipal, county and state agency land development approval process faced by developers.</p>
                                    </Col>
                                    <Col className='center-flex' xs='12' sm='12' md='12' lg='12'>
                                        <Col className='team-member center-flex' xs='12' sm='12' md='4' lg='4'>
                                            {/* <img className='img-responsive' src='//via.placeholder.com/350x400' alt='Larry Calli, Esq.' /> */}
                                            <div className='information'>
                                                <h5>{data.site.siteMetadata.team.larry.name}</h5>
                                                <button className='btn btn-secondary center-block'><a href='/team/#larry'>View Bio</a></button>
                                            </div>
                                        </Col>
                                        <Col className='team-member center-flex' xs='12' sm='12' md='4' lg='4'>
                                            {/* <img className='img-responsive' src='//via.placeholder.com/350x400' alt='Simone Calli, Esq.' /> */}
                                            <div className='information'>
                                                <h5>{data.site.siteMetadata.team.simone.name}</h5>
                                                <button className='btn btn-secondary center-block'><a href='/team/#simone'>View Bio</a></button>
                                            </div>
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section id='clients'>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12'>
                                    <h1 className='text-center'>Our Clients</h1>
                                </Col>
                                <Col className='client-list' xs='12' sm='12' md='12' lg='12'>
                                    <img className='client img-responsive' src={LogoAion} alt='AION' />
                                    <img className='client img-responsive' src={LogoAzura} alt='Azura' />
                                    <img className='client img-responsive' src={LogoBuckeye} alt='Buckeye' />
                                    <img className='client img-responsive' src={LogoBurgerKing} alt='Burger King' />
                                    <img className='client img-responsive' src={LogoCarrols} alt='Carrols' />
                                    <img className='client img-responsive' src={LogoCarvana} alt='Carvana' />
                                    <img className='client img-responsive' src={LogoDavita} alt='DaVita' />
                                    <img className='client img-responsive' src={LogoFedex} alt='FedEx' />
                                    <img className='client img-responsive' src={LogoFiamma} alt='Fiamma' />
                                    <img className='client img-responsive' src={LogoGrt} alt='GRT' />
                                    <img className='client img-responsive' src={LogoMorrisAnimalInn} alt='Morris Animal Inn' />
                                    <img className='client img-responsive' src={LogoMpac} alt='MPAC' />
                                    <img className='client img-responsive' src={LogoOewcsl} alt='OEWCSL' />
                                    <img className='client img-responsive' src={LogoPanda} alt='Panda' />
                                    <img className='client img-responsive' src={LogoPopeyes} alt='Popeyes' />
                                    <img className='client img-responsive' src={LogoQts} alt='QTS' />
                                    <img className='client img-responsive' src={LogoSilverman} alt='Silverman' />
                                    <img className='client img-responsive' src={LogoSmg} alt='SMG' />
                                    <img className='client img-responsive' src={LogoTd} alt='TD Bank' />
                                    <img className='client img-responsive' src={LogoWoodmont} alt='Woodmont' />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section id='connect'>
                        <Container>
                            <Row>
                                <Col className='display-flex' xs='12' sm='12' md='12' lg='12'>
                                    <Col xs='12' sm='12' md='12' lg='12'>
                                        <h1 className='whiteColor text-center'>Connect with Us</h1>
                                        <div className='spacer30'></div>
                                    </Col>
                                    <Col className='column' xs='12' sm='4' md='4' lg='4'>
                                        <h5>Facebook</h5>
                                        <iframe className='facebook-feed' title='facebook-feed' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCalli-Law-LLC-201705913652516&tabs=timeline&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=167680863312844" scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                    </Col>
                                    <Col className='column' xs='12' sm='4' md='4' lg='4'>
                                        <h5>Instagram</h5>
                                        <div id="curator-feed-calli-law-llc-layout"></div>
                                    </Col>
                                    <Col className='column' xs='12' sm='4' md='4' lg='4'>
                                        <h5>LinkedIn</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        <button className='btn btn-secondary center-block'><a href={data.site.siteMetadata.social.linkedInUrl}>Follow Us</a></button>
                                    </Col>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Index